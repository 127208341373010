
import { Options, Vue} from 'vue-class-component';
import AddOpportunityWork from '@/components/AddOpportunityWork.vue'
// import Vue, { PropType } from "vue";
// import Component from 'vue-property-decorator';

@Options({
  props: {
    msg: String,
  },
  components:{
    AddOpportunityWork,
  }
})

export default class OpportunityDetail extends Vue {
  msg?: string;
  // opportunity: any;
  opportunity = {
    name: null,
    client_name: null,
    category_name: null,
    val: 0,
    val_payment: 0, 
    date_open: null,
    date_close: null,
    date_payment: null,
    status: null,
    type: null,
    id: null,
  };
  base_url = "https://legacy.fk-management.com"
  sum_working_time: number = 0;
  sum_working_time_today: number = 0;
  today: Date = new Date()
  is_hidden_ow_list: boolean = true;

  OppotunityWork!: {
    id: Number;
    datetime_start: Date;
    datetime_end: Date;
    working_time: Number;
  };
  opportunity_works = [];

  getStringFromDate(date: Date) {
    var year_str = String(date.getFullYear())
    var month_str = String(1 + date.getMonth());
    var day_str = String(date.getDate());
    var format_str = 'YYYY-MM-DD';
    month_str = ('0' + month_str).slice(-2);
    day_str = ('0' + day_str).slice(-2);
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    return format_str;
  }

  
  numberFormat = (value: number)  => {
    return `¥${value.toLocaleString()}`;
  }
  $store: any;
  
  mounted () {
    // window.alert(this.base_url)
    scrollTo(0, 0)
    this.axios({
      method: "get",
      url: `${this.base_url}/drm/lancers/opportunity/${this.$route.params.id}/`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      this.opportunity = response.data;
      this.switch_ow_list()
    })
    .catch(e => {
      window.alert(e);
    })
  }

  label_status(status: string){
    let color;
    if (status == "選定/終了"){
      color = "success"
    } else if (status == "選定/作業中"){
      color = "primary"
    } else if (status == "キャンセル"){
      color = "warning"
    } else if (status == "落選"){
      color = "danger"
    } else {
      color = "info"
    }
    return `badge badge-${color}`
  }

  show_datetime(dt_str: string|null) {
    // 2020-12-19T13:30:00+09:00
    if (dt_str == null) {
      return "-"
    } else {
      return `${dt_str.slice(0, 10)} ${dt_str.slice(11, 16)}`
    }
    
  }

  switch_ow_list() {
    if (this.is_hidden_ow_list) {
      this.load_ow_list()
      this.is_hidden_ow_list = false;

    } else {
      this.is_hidden_ow_list = true;
    }
  }

  load_ow_list() {
    // Opportunity Worki List
    let ow_list: any[] = [];
    this.sum_working_time = 0;
    this.sum_working_time_today = 0;
    this.axios({
      method: "get",
      url: `${this.base_url}/drm/lancers/opportunitywork/?opportunity=${this.$route.params.id}&limit=1000`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      for (const ow in response.data.results) {
        this.sum_working_time += response.data.results[ow].working_time
        try {
          let val = response.data.results[ow].datetime_start
          let date_str = (val.substr(0, 10) + " " + val.substr(11, 5)).slice(0,10)
          if (date_str == this.getStringFromDate(this.today)){
            this.sum_working_time_today += response.data.results[ow].working_time
          }
        }catch(e){
          window.alert(e)
        }
      }
      this.sum_working_time = this.sum_working_time/60
      this.sum_working_time_today = this.sum_working_time_today/60
      this.opportunity_works = response.data.results
    })
    .catch(e => {
      window.alert(e.response.data)
    })
  }

  load_opportunity () {
    this.axios({
      method: "get",
      url: `${this.base_url}/drm/lancers/opportunity/${this.$route.params.id}/`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      this.opportunity = response.data;
      this.switch_ow_list()
    })
    .catch(e => {
      window.alert(e);
    })
  }

  change_status(status) {
    if (window.confirm(`${status}にしますか？`)){
      let data = {
        status: status,
      }
      if (status=="選定/終了" && this.opportunity.type!="MENTA") {
        data['date_close'] = data['date_payment'] = new Date().toISOString().split("T")[0];
      } else if (status=="選定/終了" && this.opportunity.type=="MENTA") {
        data['date_close'] = new Date().toISOString().split("T")[0];
      } else if(status=="選定/作業中" && this.opportunity.type=="MENTA"){
        data['date_payment'] = this.opportunity.date_open
      }
      this.axios({
        method: "patch",
        url: `${this.base_url}/drm/lancers/opportunity/${this.$route.params.id}/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${this.$store.state.jwt}`,
        },
        data: data
      })
      .then(response => {
        this.load_opportunity()
        this.$store.commit("setMessageSuccess", `Updated status successfully! ${response.data.status}`)
        scrollTo(0,0)
      })
      .catch(e => {
        if (e.response.status == 401){
          this.$store.commit("setMessageError", "Not authorized")
          this.$router.push({name: 'Login'})
        } else {
          this.$store.commit("setMessageError", `Failed to update status! ${e.response.data.detail}`)
          scrollTo(0,0)
        }
      })
    }
  }
}
