
import { Options, Vue } from 'vue-class-component';
// import {Component, Vue} from 'vue-property-decorator';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import OpportunityList from '@/components/OpportunityList.vue'
import OpportunityWorkList from '@/components/OpportunityWorkList.vue';
import component from '*.vue';

@Options({
  components: {
    HelloWorld,
    OpportunityList,
    OpportunityWorkList
  },
})
// @Component({})
export default class Home extends Vue {
}
