
import { Options, Vue} from 'vue-class-component';
import AddOpportunityWork from '@/components/AddOpportunityWork.vue'
// import AlertBadge from '@/components/Alert.vue'
// import Vue, { PropType } from "vue";
// import Component from 'vue-property-decorator';

@Options({
  props: {
    msg: String,
  },
  components:{
    AddOpportunityWork,
    // AlertBadge,
  }
})

export default class OpportunityList extends Vue {
  msg?: string;
  next?: URL;
  current?: URL;
  previous?: URL;
  offset?: number
  status?: string;
  count?: number;
  order_by?: string | null;

  base_url = "https://legacy.fk-management.com"
  opportunities = []
  status_list = ["相談中", "提案中", "選定/作業中", "選定/終了", "キャンセル", "落選"]
  
  mounted () {
    if ("offset" in this.$route.query) {
      this.offset = Number(this.$route.query.offset);
    } else {
      this.offset = 0;
    }
    this.current = new URL(
      `${this.base_url}/drm/lancers/opportunity/?limit=20&offset=${this.offset}`
    )
    this.status = this['$store'].getters.getListFilter.status
    this.order_by = this['$store'].getters.getListFilter.order_by
    this.filter_status(this.status)
  }

  numberFormat = (value: number)  => {
    return `¥${value.toLocaleString()}`;
  }

  show_next () {
    window.alert(this.next)
  }

  set_order_by(val: string) {
    if (this.order_by == val) {
      this.order_by = "-" + val
    } else if (this.order_by == "-"+val) {
      this.order_by = null
    } else {
      this.order_by = val
    }
    this.filter_status(this.status)
  }

  filter_status (status?: string) {
    this.status = status;
    this['$store'].commit("setListFilter", {
      status: this.status,
      order_by: this.order_by
    });
    if (this.current != null ){
      this.current.searchParams.delete("offset")
      this.current.searchParams.delete("status")      
      if (status != null){
        this.current.searchParams.append("status", status)
      }
      if (this.order_by != null){
        this.current.searchParams.append("order_by", this.order_by)
      } else {
        this.current.searchParams.delete("order_by")      
      }
      this.move(this.current)
    } else {
      window.alert("status error")
    }
  }

  move (url: URL) {
    this.axios({
      method: "get",
      url: String(url),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      this.current = url;
      if (response.data.next == null){
        this.next = response.data.next;
      } else {
        this.next = new URL(response.data.next);
      }
      if (response.data.previous == null) {
        this.previous = response.data.previous;  
      } else {
        this.previous = new URL(response.data.previous);
      }
      this.opportunities = response.data.results;
      this.count = response.data.count;
    })
    .catch(e => {
      window.alert(e);
    })
  }

  label_status(status: string){
    let color;
    if (status == "選定/終了"){
      color = "success"
    } else if (status == "選定/作業中"){
      color = "primary"
    } else if (status == "キャンセル"){
      color = "warning"
    } else if (status == "落選"){
      color = "danger"
    } else {
      color = "info"
    }
    return `badge badge-${color}`
  }

  get_status() {
    if (this.status != null) {
      return this.status
    } else {
      return "ALL"
    }
  }

  is_over_deadline(date_close: string) {
    return new Date() > new Date(date_close)
  }
}

